/*
    This exposes one font: "GTWalsheimPro", which will pick one of the three fonts, depending on the chosen font-weight.
*/

@font-face {
  font-family: 'GTWalsheimPro';
  src: url(GTWalsheimProRegular.woff2) format('woff2'),
    url(GTWalsheimProRegular.woff) format('woff'),
    url(GTWalsheimProRegular.ttf) format('truetype'),
    url(GTWalsheimProRegular.svg#GTWalsheimProRegular) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url(GTWalsheimProMedium.woff2) format('woff2'),
    url(GTWalsheimProMedium.woff) format('woff'),
    url(GTWalsheimProMedium.ttf) format('truetype'),
    url(GTWalsheimProMedium.svg#GTWalsheimProMedium) format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url(GTWalsheimProBold.woff2) format('woff2'),
    url(GTWalsheimProBold.woff) format('woff'),
    url(GTWalsheimProBold.ttf) format('truetype'),
    url(GTWalsheimProBold.svg#GTWalsheimProBold) format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
