.footer {
  // color: var(--color-white);
  // background-color: var(--color-primary);
  color: var(--color-primary);
  background: var(--color-background);
  border-top: 1px solid var(--color-grey-5);
  h3 {
    font-weight: bold;
    margin-bottom: var(--padding-inner-xs);
  }
}

.waves {
  fill: var(--color-background);
  margin-bottom: 48px;
}

.container {
  margin: 24px var(--padding-inner-md) var(--padding-inner-sm);
  max-width: var(--width-page, 960px);

  @media (max-width: 960px) {
    padding: 0 var(--padding-inner-sm);
  }
}

.bottom {
  margin-top: 24px;
  font-size: 0.875rem;
  color: var(--color-grey-3);
  fill: var(--color-grey-3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-inner-xs);
}

.social {
  align-items: center;
  display: flex;

  a {
    margin-left: var(--padding-inner-xs);
  }

  svg {
    // fill: var(--color-grey-6);

    // &:hover {
    //   fill: var(--color-white);
    // }
    fill: var(--color-grey-3);
    &:hover {
      fill: var(--color-grey-1);
    }
  }
}
