.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--padding-inner-xs);

  border-radius: var(--border-radius-sm);
  //min-width: 120px;
  padding: var(--padding-inner-xs) var(--padding-inner-sm);
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: var(--font-size-body);
  line-height: 24px;
  letter-spacing: 0.02em;

  background-color: var(--color-cta);
  color: var(--color-white);
  border: none;

  svg {
    flex: 0 0 auto;
  }

  &:focus-visible {
    outline: 2px solid var(--color-focus);
    outline-offset: 2px;
  }

  &:hover {
    background-color: var(--color-cta-primary-hover);
    cursor: pointer;
  }

  &:active {
    background-color: var(--color-cta-primary-pressed);
  }

  &.disabled {
    color: var(--color-grey-3);
    background-color: var(color-cta-primary-disabled);
    cursor: not-allowed;
  }

  &.secondary {
    color: var(--color-cta);
    border: 1px solid var(--color-cta);
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: var(--color-cta-secondary-hover);
    }

    &:active {
      background-color: #ffe7dc; // TODO: define variable
    }

    &.disabled {
      color: var(--color-grey-4);
      background-color: var(color-cta-secondary-disabled);
      border: 1px solid var(--color-grey-4);
    }
  }

  // ? Not sure if we need this
  &.secondaryDark {
    box-shadow: inset 0 0 0 1px var(--color-dark-blue);
    color: var(--color-dark-blue);
    background-color: transparent;

    &:hover {
      background-color: hsla(210, 99%, 45%, 0.08); // TODO: define variable
    }

    &:active {
      background-color: hsla(210, 99%, 45%, 0.04); // TODO: define variable
    }

    &.disabled {
      color: var(--color-grey-3);
      background-color: transparent;
      box-shadow: inset 0 0 0 1px var(--color-grey-5);
    }
  }

  // ? Probably remove this since we only have a primary and secondary button now
  &.tertiary {
    background-color: var(--color-orange);

    &:hover {
      background-color: hsla(210, 61%, 26%, 1); // TODO: define variable
    }

    &:active {
      background-color: hsla(210, 42%, 35%, 1); // TODO: define variable
    }

    &.disabled {
      background-color: var(--color-grey-5);
    }
  }

  &.label {
    display: inline-flex;
    padding: 0;
    margin: 0;
    font: inherit;
    background: none;
    color: var(--color-blue);
    min-width: auto;

    &:hover {
      text-decoration: underline;
    }
  }

  &.darkLabel {
    display: inline-flex;
    padding: 0;
    margin: 0;
    font: inherit;
    font-size: var(--font-size-caption);
    background: none;
    color: var(--color-black);
    min-width: auto;
    text-decoration-line: underline;
  }

  &.fullWidth {
    width: 100%;
  }
}
