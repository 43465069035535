.columns {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 calc(-1 * var(--padding));

  @media (max-width: 780px) {
    flex-wrap: wrap;
  }

  h4 {
    padding: 0 8px 8px;
  }

  a {
    display: block;
    color: currentColor;
    text-decoration: none;
    padding: 4px 8px;
    border-radius: var(--border-radius-sm);

    &:hover {
      background-color: var(--color-grey-6);
    }
  }
}

.column {
  width: 100%;
  padding: var(--padding);

  @media (max-width: 800px) {
    width: 33%;
    padding: var(--padding) var(--padding) calc(3 * var(--padding));
  }

  @media (max-width: 500px) {
    width: 50%;
    padding: var(--padding) var(--padding) calc(3 * var(--padding));
  }

  ul {
    list-style: none;
    padding: 0;
  }
}
