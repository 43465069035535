.header {
  background: var(--color-white);
  box-shadow: var(--shadow);
  position: relative;
  width: 100vw;
  z-index: 25;
  display: flex;
  padding: var(--padding-inner-sm);
  align-items: center;
  flex: 0 0 auto;
  gap: var(--padding-inner-md);

  @media screen and (min-width: 800px) {
    flex: 0 0 var(--header-height, 80px);
    padding: 20px var(--padding-inner-md);
  }
}

.logo {
  flex: 0 0 auto;
  min-width: 0;

  svg {
    display: block;
    height: 1.5rem;
    fill: var(--color-logo);
  }
}

.actions {
  flex: 1 1 auto;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: 620px) {
    justify-content: space-between;
  }

  & > div {
    display: flex;
    gap: 12px;
  }

  .action {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding: 0 var(--padding-inner-xs);
    gap: var(--padding-inner-xs);
    text-decoration: none;

    &.largeAndUp {
      @media (max-width: 900px) {
        display: none !important;
      }
    }

    &.mediumAndUp {
      @media (max-width: 770px) {
        display: none !important;
      }
    }

    &.search {
      @media (min-width: 620px) {
        .icon {
          display: none;
        }
      }
    }

    .text {
      @media (max-width: 620px) {
        display: none;
      }
    }

    .favoritesIcon {
      position: relative;

      & span {
        position: absolute;
        color: #ff4b00;
        left: 0;
        right: 0;
        top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-meta);
      }

      &.animateFavorites {
        animation-name: scale;
        animation-duration: 500ms;
        animation-timing-function: ease-in-out;
      }
    }
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .feedbackButton {
    color: currentColor;
    animation: fadeIn ease-in 300ms;
    animation-fill-mode: backwards;
    animation-delay: 0;

    svg {
      color: var(--color-grey-2);
    }

    .text,
    .longText {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    @media (min-width: 620px) {
      .text {
        display: block;
      }
    }

    @media (min-width: 950px) {
      .longText {
        display: block;
      }

      .text {
        display: none;
      }
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.menu {
  background-color: var(--color-background);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 500px;
  overflow-y: scroll;
  overflow-x: hidden;

  padding: 112px 32px 32px;

  transition-property: transform, opacity;

  &[data-status='open'],
  &[data-status='close'] {
    transition-duration: 250ms;
  }
  &[data-status='initial'],
  &[data-status='close'] {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  &[data-status='open'] {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .menuHeader {
    font-size: var(--font-size-caption);
    margin-bottom: 8px;
    margin-left: 16px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding-left: 16px;
    height: var(--header-height, 56px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grey-6);

    a {
      text-decoration: none;
      display: flex;
      flex: 1 0 auto;
      justify-content: space-between;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-of-type {
      margin-bottom: 48px;
    }
  }
}

.subMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  background: var(--color-white);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius-md);

  .subMenuItem {
    display: block;
    padding: var(--padding-inner-xs) var(--padding-inner-sm);
    border-radius: var(--border-radius-sm);
    outline: none;
  }

  li {
    border-bottom: 1px solid var(--color-grey-6);
    padding: var(--padding-inner-xxs);

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.overlay {
  background: var(--color-overlay);
  z-index: 15;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
