:root {
  --color-orange: hsla(18, 100%, 50%, 1);
  --color-blue: hsla(210, 99%, 45%, 1);
  --color-light-blue: hsla(209, 79%, 89%, 1);
  --color-light-blue-dimmed: hsla(209, 79%, 89%, 0.5);
  --color-dark-blue: hsla(210, 100%, 18%, 1);
  --color-green: hsla(135, 58%, 42%, 1);
  --color-red: hsla(3, 100%, 46%, 1);

  --color-black: #243240;
  --color-grey-0: #b2b7bc;
  --color-grey-1: #4f5864;
  --color-grey-2: #5f6972;
  --color-grey-3: #8c949b;
  --color-grey-4: #b2b7bc;
  --color-grey-5: #d3d6d9;
  --color-grey-6: #e9ebec;
  --color-grey-7: #f7f8f8;
  --color-white: hsla(0, 0%, 100%, 1);

  --color-logo: var(--color-orange, #ff4b00);
  --color-background-header: var(--color-white);
  --color-background: var(--color-white);
  --color-error: var(--color-red);
  --color-focus: var(--color-black);
  --color-error-toast-border: hsla(0, 100%, 91%, 1);
  --color-error-toast-background: hsla(0, 100%, 98%, 1);
  --color-warning-toast-border: hsla(52, 98%, 78%, 1);
  --color-warning-toast-background: hsla(43, 100%, 95%, 1);
  --color-success-toast-border: hsla(153, 94%, 80%, 1);
  --color-success-toast-background: hsla(153, 100%, 96%, 1);

  --color-overlay: hsla(210, 28%, 20%, 0.25);
  --color-hover: var(--color-grey-7);

  --color-cta: var(--color-orange);

  --color-cta-primary-hover: hsla(18, 100%, 56%, 1);
  --color-cta-primary-pressed: #ff6424;
  --color-cta-primary-disabled: var(--color-grey-6);

  --color-cta-secondary-hover: #ffe7dc;
  --color-cta-secondary-pressed: #ffe7dc;
  --color-cta-secondary-disabled: var(--color-grey-6);

  --color-form-background: var(--color-white);
  --color-form-border: var(--color-grey-3);
  --color-form-focus: var(--color-black);
  --color-form-active: var(
    --color-black
  ); // E.g. checked checkbox, select radio, on-switch
  --color-form-disabled-background: var(--color-grey-6);

  --color-tooltip-background: var(--color-black);
  --color-tooltip-text: var(--color-white);

  --color-popover-background: var(--color-white);
  --color-popover-text: var(--color-black);
  --color-popover-border: var(--color-grey-5);

  --color-text: var(--color-black);
  --color-primary: var(--color-black);
  --color-secondary: var(--color-blue);
  --color-tertiary: var(--color-dark-blue);
}

.color-primary {
  // TODO: Am I redoing TailwindCSS here? :S
  color: var(--color-primary);
}
