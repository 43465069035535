.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--padding-inner-xs);

  &.error {
    input[type='text'] {
      border-color: var(
        --color-error
      ) !important; // TODO: Make it more specific so we can remove !important
    }
  }

  &:hover {
    input[type='text'] {
      border-color: var(--color-color-dark-blue);
    }
  }

  label {
    font-size: 14px;
    line-height: 19px; // TODO: Do we want to do this?
    margin-bottom: var(--padding-inner-xs);
  }

  input[type='text'] {
    border: 1px solid var(--color-form-border);
    border-radius: var(--border-radius-sm);
    font: inherit;
    background: var(--color-form-background);
    line-height: 21px; // TODO: Do we want to do this?
    margin: 0;
    padding: 12px 15px 13px 15px;

    &:invalid {
      border-color: var(--color-error);
    }

    &:focus {
      outline: 2px solid var(--color-form-focus);
      outline-offset: -1px;
    }

    &:disabled {
      background: var(--color-form-disabled-background);
    }

    &:not(:placeholder-shown) {
      // TODO: This requires a non-empty placeholder to work e.g. " "
      border-color: var(--color-color-dark-blue);
    }
  }

  &:focus-within {
    label {
      // color: var(--color-primary);
    }
  }

  span {
    margin-top: var(--padding-inner-xs);
    font-size: 14px;
    line-height: 19px; // TODO: Do we want to do this?
  }

  span.info {
    color: var(--color-grey-2);
  }

  span.error {
    color: var(--color-error);
  }
}

.label {
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  padding: var(--padding-inner-xxs, 4px);
  cursor: pointer;

  &:hover {
    background-color: var(--color-hover);
  }
}

input[type='checkbox'],
input[type='radio'] {
  flex: 0 0 24px;
  appearance: none;
  height: 24px;
  width: 24px;
  background: #fff;
  border: 2px solid var(--color-form-border);
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline-offset: 5px;
  outline-color: var(--color-form-border);
  font: initial;

  &:disabled {
    opacity: 0.7;
    background: var(--color-form-disabled-background);
    cursor: not-allowed;
  }

  &:after {
    content: '';
    display: block;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked:after {
    transform: scale(1);
  }
}

input[type='checkbox'] {
  border-radius: 5px;

  &:after {
    width: 16px;
    height: 16px;
  }

  &:checked {
    border: none;
    background-color: var(--color-form-active);

    &:after {
      content: url("data:image/svg+xml, <svg viewBox='0 0 16 15' xmlns='http://www.w3.org/2000/svg'><path fill='white' d='M15.25.847a1.51 1.51 0 0 1 .405 2.096L8.106 14.11a1.944 1.944 0 0 1-2.94.329L.6 10.156a1.51 1.51 0 1 1 2.067-2.202l3.645 3.42 6.841-10.122a1.51 1.51 0 0 1 2.098-.405Z'/></svg>");
    }
  }
}

input[type='radio'] {
  border-radius: 20px;

  &:after {
    width: 12px;
    height: 12px;
    border-radius: 6px;
  }

  &:checked {
    border-color: var(--color-form-active);

    &:after {
      background-color: var(--color-form-active);
    }
  }
}

input[type='checkbox'][role='switch'] {
  position: absolute;
  clip-path: inset(50%);

  & + label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 24px;
    background: var(--color-form-border);
    display: block;
    border-radius: 100px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 10px;
      transition: 0.3s;
    }

    &:active:after {
      width: 30px;
    }
  }

  &:checked + label {
    background: var(--color-form-active);

    &:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
}

.range,
.numeric {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--padding);
}
