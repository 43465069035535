@import 'reset.css';
@import 'colors.scss';
@import 'controls.scss';
@import '../public/fonts/walsheim/include.css';
@import 'typography.scss';

:root {
  --base-padding: 0.5rem; // 8px
  // Shapes & Spacing
  --padding: 0.5rem;
  --padding-inner-xxs: calc(var(--base-padding) / 2); // 4px
  --padding-inner-xs: var(--base-padding); // 8px
  --padding-inner-sm: calc(var(--base-padding) * 2); // 16px
  --padding-inner-md: calc(var(--base-padding) * 3); // 24px
  --padding-inner-lg: calc(var(--base-padding) * 4); // 32px
  --padding-inner-xl: calc(var(--base-padding) * 5); // 40px
  --padding-inner-xxl: calc(var(--base-padding) * 6); // 48px

  --border-radius-sm: calc(var(--base-padding) / 2);
  --border-radius-md: var(--base-padding);

  --shadow: 0px 2px 8px 0px rgba(36, 50, 64, 0.08);
  --shadow-inner: inset 0px -2px 0px rgba(36, 50, 64, 0.15);

  // Layout
  --width-page: 960px;
  --header-height: 56px;
  @media (min-width: 800px) {
    --header-height: 80px;
  }
}

body {
  overflow-x: hidden; // Avoid rogue ads from breaking the layout (e.g. when loading the site in a large window, and then reducing the window size)
}

* {
  outline-color: var(--color-focus);

  &:focus-visible {
    outline: 2px solid var(--color-focus);
    outline-offset: 2px;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 16px;
  }
}

body {
  color: var(--color-text);
  font-family: var(--font-family);
  background-color: var(--color-grey-6);
  margin: 0;
}

main {
  flex: 1 1 auto;
  background-color: var(--color-background);
}

a {
  color: inherit;
}

hr {
  border: 0;
  height: 1px;
  background: var(--color-grey-6);
  margin: var(--padding-inner-sm) 0;
  grid-column: 1 / -1;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

/* 
 * Utility class to hide content visually while keeping it screen reader-accessible.
 * Source: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html 
 */

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--width-page);
  margin: 0 auto;
  padding: 0;
}

.object-fit-cover {
  object-fit: cover;
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 var(--padding-inner-md);

  & > div {
    min-width: 0;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
}

.spanAllColumns {
  grid-column: 1 / -1;
}

div.closeToNext {
  margin-bottom: 0.5rem;
}

button {
  color: currentColor;
  padding: unset; // IOS will apply a 16px padding to all buttons
}

.flexWrapCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--padding-inner-xs, 8px);
  flex-wrap: wrap;
}
