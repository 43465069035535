:root {
  --font-family: 'GTWalsheimPro', sans-serif;
  --font-size-heading1: 2rem; // 32px
  --font-size-title: 1.5rem; // 24px
  --font-size-subtitle: 1.125rem; // 18px
  --font-size-body: 1rem; // 16px
  --font-size-caption: 0.875rem; // 14px
  --font-size-meta: 0.75rem; // 12px
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  font-size: var(--font-size-body);
  line-height: 1.3;
}

h1,
.heading1 {
  font-weight: bold;
  font-size: var(--font-size-heading1);
  margin-bottom: 0.5em;
}

h2,
.title {
  font-weight: bold;
  font-size: var(--font-size-title);
  margin-bottom: 0.5em;
}

h3,
.subtitle {
  font-weight: normal;
  font-size: var(--font-size-subtitle);
}

p {
  margin-bottom: 0.5rem;
}

caption,
.caption {
  font-weight: normal;
  font-size: var(--font-size-caption);
}

.meta {
  font-size: var(--font-size-meta);
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}
